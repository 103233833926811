import { Heading } from '@hexa-ui/components';
import styled, { createGlobalStyle } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 48px;
  #mfe-content-header {
    display: none;
  }
`;

export const HideAppHeader = createGlobalStyle`
  #mfe-content-header {
    display: none
  }
`;

export const HeadingElement = styled(Heading)`
  margin: 56px 0 48px 0;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
`;
