import MessageMap from '../i18n.d';

const ptBR: MessageMap = {
  titleSwitch: {
    title: 'O que você gostaria de acessar?',
  },
  cardSwitch: {
    title: {
      taskManagement: 'Gestão de tarefas',
      OperationsPanel: 'Operation panel',
      taskGroups: 'Grupos de tarefas',
      userCreation: 'Criação de usuário',
      audienceBuilder: 'Criação de audiência',
    },
    description: {
      taskManagement: 'Crie e gerencie tarefas',
      OperationsPanel: `Search by BDRs, accounts, visits, and tasks in Force's database.`,
      taskGroups: 'Criar, editar e priorizar grupos de tarefas.',
      userCreation:
        'Crie contas para BEES One e Force para gerenciar efetivamente o fluxo de trabalho dos usuários.',
      audienceBuilder: 'Crie audiências usando filtros ou listas predefinidas.',
    },
  },
  test: {
    translate: 'Tradução',
  },
};

export default ptBR;
