import {
  createInstance,
  OptimizelyProvider,
  ReactSDKClient,
  setLogger,
} from '@optimizely/react-sdk';
import { useAppHeader, useAuthenticationService } from 'admin-portal-shared-services';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import PageError from '../../pages/PageError';
import PageLoading from '../../pages/PageLoading';
import { useEnvContext } from '../EnvProvider/EnvProvider';

interface OptimizelyManagerProps {
  children?: JSX.Element;
  client?: ReactSDKClient;
}

const OptimizelyManager = ({ children, client }: OptimizelyManagerProps): JSX.Element => {
  const authenticationService = useAuthenticationService();
  const [appHeaderConfig] = useAppHeader();
  const [isOptimizelyClientReady, setIsOptimizelyClientReady] = useState<boolean>(false);
  const [isOptimizelyLoaded, setIsOptimizelyLoaded] = useState<boolean>(false);
  const [isOptimizelyError, setIsOptimizelyError] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const EnvContext = useEnvContext();
  const successOptimizelyKey = !!EnvContext.OPTIMIZELY_KEY;
  const userId = authenticationService.getUserId();
  const country = appHeaderConfig.selectedCountry ?? authenticationService.getCountryB2C();
  const vendorId = appHeaderConfig?.selectedVendor ?? authenticationService.getVendorId();
  const optimizelyClient =
    client ??
    createInstance({
      sdkKey: EnvContext.OPTIMIZELY_KEY,
      eventBatchSize: 10,
      eventFlushInterval: 1000,
    });
  const attributes = {
    country,
    vendorId,
  };

  setLogger(null);

  optimizelyClient.onReady().then(() => {
    setIsOptimizelyLoaded(true);
    if (isOptimizelyClientValid()) {
      setIsOptimizelyClientReady(true);
    } else {
      setIsOptimizelyError(true);
    }
  });

  function isOptimizelyClientValid() {
    return optimizelyClient.getOptimizelyConfig() !== null;
  }

  if (!successOptimizelyKey || isOptimizelyError) {
    return (
      <PageError
        error={{
          message: formatMessage({ id: 'message.error.optimizelyNotLoaded' }),
        }}
      />
    );
  }

  if (!isOptimizelyLoaded && !isOptimizelyClientReady) {
    return <PageLoading />;
  }

  return (
    <OptimizelyProvider optimizely={optimizelyClient} user={{ id: userId, attributes }}>
      {isOptimizelyClientReady && children}
    </OptimizelyProvider>
  );
};

export default OptimizelyManager;
