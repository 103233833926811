import { useIntl } from 'react-intl';

import { CategorySearch, Clipboard, List2, MapPin, Users } from '@hexa-ui/icons';
import { SidebarItem, useSidebar } from 'admin-portal-shared-services';
import { useCheckPermissions } from '../../../pages/Switch/hooks/useCheckPermissions';

export const SidebarIcons = {
  Tasks: (): JSX.Element => <Clipboard />,
  OperationsPanel: (): JSX.Element => <CategorySearch />,
  TaskGroups: (): JSX.Element => <List2 />,
  Users: (): JSX.Element => <Users />,
  Visits: (): JSX.Element => <MapPin />,
};

export function useSidebarService(): void {
  const { formatMessage } = useIntl();
  const {
    hasVTwoCardPermission,
    hasVOneCardPermission,
    hasOperationsReadTaskPermission,
    hasTaskGroupsPermission,
    hasTaskManagementPermission,
    isFrontlineSidebarVisible,
    isForceAdminVisitsEnabled,
    isForceTaskPoolEnabled,
  } = useCheckPermissions();
  const sidebarItems: SidebarItem[] = [];

  if (hasVTwoCardPermission) {
    sidebarItems.push({
      id: 'tasks-v2',
      title: formatMessage({ id: 'sidebar.title.pageTasks' }),
      icon: SidebarIcons.Tasks,
      path: isFrontlineSidebarVisible ? '/force/frontline/tasks' : '/frontline/tasks',
    });
  }

  if (hasVOneCardPermission) {
    sidebarItems.push({
      id: 'tasks-v1',
      title: formatMessage({ id: 'sidebar.title.pageTasks' }),
      icon: SidebarIcons.Tasks,
      path: '/force/task-management',
    });
  }

  if (isForceTaskPoolEnabled) {
    sidebarItems.push({
      id: 'task-pool',
      title: formatMessage({ id: 'sidebar.title.pageTasks' }),
      icon: SidebarIcons.Tasks,
      path: '/force/tasks',
    });
  }

  if (hasOperationsReadTaskPermission) {
    sidebarItems.push({
      id: 'operation',
      title: formatMessage({ id: 'sidebar.title.pageOperations' }),
      icon: SidebarIcons.OperationsPanel,
      path: '/force/operations-panel',
    });
  }

  if (isForceAdminVisitsEnabled) {
    sidebarItems.push({
      id: 'visits',
      title: formatMessage({ id: 'sidebar.title.pageVisits' }),
      icon: SidebarIcons.Visits,
      path: '/force/visits',
    });
  }

  if (hasTaskGroupsPermission) {
    sidebarItems.push({
      id: 'task-groups',
      title: formatMessage({ id: 'sidebar.title.pageTaskGroups' }),
      icon: SidebarIcons.TaskGroups,
      path: isFrontlineSidebarVisible ? '/force/frontline/task-groups' : '/frontline/task-groups',
    });
  }

  if (hasTaskManagementPermission) {
    sidebarItems.push({
      id: 'audience-builder',
      title: formatMessage({ id: 'sidebar.title.pageAudienceBuilder' }),
      icon: SidebarIcons.Users,
      path: '/audiences?origin=force',
    });
  }

  useSidebar({
    items: sidebarItems,
    utils: [],
  });
}
