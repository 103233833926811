import React from 'react';

export interface EnvConfig {
  name: string;
  ENV: string;
  ENV_TYPE: string;
  OPTIMIZELY_KEY: string | undefined;
}

export const defaultInitialEnvConfig: EnvConfig = {
  name: '',
  ENV: '',
  ENV_TYPE: '',
  OPTIMIZELY_KEY: '',
};

export const EnvContext = React.createContext(defaultInitialEnvConfig);

export const useEnvContext = (): EnvConfig => React.useContext(EnvContext);

export const EnvProvider = ({
  env,
  children,
}: {
  env: EnvConfig;
  children: React.ReactNode;
}): JSX.Element => <EnvContext.Provider value={env}>{children}</EnvContext.Provider>;
