import { useDecision } from '@optimizely/react-sdk';
import { useHasPermission } from 'admin-portal-shared-services';
import {
  OPTIMIZELY_FORCE_ADMIN_VISITS,
  OPTIMIZELY_FORCE_TASK_POOL,
  OPTIMIZELY_FRONTLINE_SIDEBAR,
  OPTIMIZELY_KEY_CARD_OPERATIONS_PANEL,
} from '../../../consts/optimizely';
import { Scopes } from '../../../models/scopes';

export interface UseCheckPermissionsResponse {
  isOperationsPanelVisible: boolean;
  isFrontlineSidebarVisible: boolean;
  isForceAdminVisitsEnabled: boolean;
  isForceTaskPoolEnabled: boolean;
  hasOperationsReadTaskPermission: boolean;
  hasVOneCardPermission: boolean;
  hasVTwoCardPermission: boolean;
  hasTaskGroupsPermission: boolean;
  hasInviteUserPermission: boolean;
  hasTaskManagementPermission: boolean;
}

export function useCheckPermissions(): UseCheckPermissionsResponse {
  const [operationsPanel, operationsPanelReady] = useDecision(OPTIMIZELY_KEY_CARD_OPERATIONS_PANEL);
  const [frontlineSidebar, frontlineSidebarReady] = useDecision(OPTIMIZELY_FRONTLINE_SIDEBAR);
  const [forceAdminVisits, forceAdminVisitsReady] = useDecision(OPTIMIZELY_FORCE_ADMIN_VISITS);
  const [forceTaskPool, forceTaskPoolReady] = useDecision(OPTIMIZELY_FORCE_TASK_POOL);

  const hasOperationsReadTaskPermission = useHasPermission([Scopes.BeesForceOperationsGlobalRead]);

  const isOperationsPanelVisible = operationsPanel.enabled && operationsPanelReady;

  const hasVOneCardPermission = useHasPermission([Scopes.BeesForceVOneCardRead]);
  const hasVTwoCardPermission = useHasPermission([Scopes.BeesForceVTwoCardRead]);
  const hasTaskGroupsPermission = useHasPermission(
    [Scopes.BeesForceTaskGroupsRead, Scopes.BeesForceTaskGroupsWrite],
    'OR'
  );
  const hasForceAdminVisitsPermission = useHasPermission([Scopes.BeesForceVisitsManagementWrite]);
  const hasInviteUserPermission = useHasPermission([Scopes.BeesForceAdminInviteUser]);
  const hasTaskManagementPermission = useHasPermission([Scopes.BeesForceTaskManagementWrite]);

  const isFrontlineSidebarVisible = frontlineSidebar.enabled && frontlineSidebarReady;
  const isForceAdminVisitsEnabled =
    forceAdminVisits.enabled && forceAdminVisitsReady && hasForceAdminVisitsPermission;
  const isForceTaskPoolEnabled = forceTaskPool.enabled && forceTaskPoolReady;

  return {
    isFrontlineSidebarVisible,
    isOperationsPanelVisible,
    isForceAdminVisitsEnabled,
    isForceTaskPoolEnabled,
    hasOperationsReadTaskPermission,
    hasVOneCardPermission,
    hasVTwoCardPermission,
    hasTaskGroupsPermission,
    hasInviteUserPermission,
    hasTaskManagementPermission,
  };
}
