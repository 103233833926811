import { createGenerateClassName, StylesProvider } from '@material-ui/core';
import { IntlProvider } from './providers/IntlProvider';
import { EnvConfig, EnvProvider } from './providers/EnvProvider';
import OptimizelyProvider from './providers/OptimizelyProvider';
import Router from './Router';

export default function App(props: EnvConfig): JSX.Element {
  const generateClassName = createGenerateClassName({
    productionPrefix: 'bees-hub-force-switch-mfe',
    seed: 'bees-hub-force-switch-mfe',
  });

  return (
    <EnvProvider env={props}>
      <StylesProvider generateClassName={generateClassName}>
        <IntlProvider>
          <OptimizelyProvider>
            <Router />
          </OptimizelyProvider>
        </IntlProvider>
      </StylesProvider>
    </EnvProvider>
  );
}
