import { usePreferredLanguageV2 } from 'admin-portal-shared-services';
import { ReactNode } from 'react';
import { IntlProvider as NativeProvider } from 'react-intl';
import MessageMap from '../../i18n/i18n.d';
import enUS from '../../i18n/languages/en-US';
import es from '../../i18n/languages/es';
import idID from '../../i18n/languages/id-ID';
import ptBR from '../../i18n/languages/pt-BR';
import { flattenObject } from '../../utils/objects';

const DEFAULT_LANGUAGE = 'en-US';
type IIntlProvider = {
  children: ReactNode;
};

export const messages: { [language: string]: MessageMap } = {
  'en-US': enUS,
  'pt-BR': ptBR,
  'es-419': es,
  'id-ID': idID,
};

export function IntlProvider({ children }: IIntlProvider): JSX.Element {
  const { preferredLanguage } = usePreferredLanguageV2();

  let locale;

  switch (preferredLanguage) {
    case 'pt-BR':
      locale = ptBR;
      break;
    case 'en-US':
      locale = enUS;
      break;
    case 'es-419':
      locale = es;
      break;
    default:
      locale = enUS;
  }

  const mergedMessages = mergeMessages(messages, preferredLanguage);

  return (
    <NativeProvider locale={preferredLanguage} defaultLocale={locale} messages={mergedMessages}>
      {children}
    </NativeProvider>
  );
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any */

/* eslint-enable */

export const mergeMessages = (messagesInput: MessageMap, selectedLocale: string): any => {
  const defaultMessages = flattenObject(messagesInput[DEFAULT_LANGUAGE]);
  const localeMessages = flattenObject(messagesInput[selectedLocale]);
  return { ...defaultMessages, ...localeMessages };
};

export default IntlProvider;
